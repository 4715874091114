import type { Ref } from 'vue';
import { storeToRefs } from 'pinia';
import Social from '@services/social';
import type { SocialLogins } from '@/shared/elements/registration/definitions';
import type { Page } from '@/shared/types/model';
import type { RegisterPayload } from '@/types/register';
import { useSiteStore } from '@/stores/site';
import emitter from '@shared/utils/emitter';
import { GTM, GA4 } from '@/utils/integrations';
import { checkCurrentSession } from '@/utils/session';
import { useRegisterCommon } from './common/useRegisterCommon';
import { useDocLinks } from './useDocLinks';

function registerGTMError(message: string) {
  GTM.push({
    event: 'error',
    errorCategory: 'Registration',
    errorType: message
  });

  GA4.gtag('event', 'error', {
    errorCategory: 'Registration',
    errorType: message
  });
}

function registerGTMSuccess() {
  GTM.push({
    event: 'success',
    pageType: 'Registration',
    pageSlug: globalThis?.location?.pathname
  });

  GA4.gtag('event', 'success', {
    pageType: 'Registration',
    pageSlug: globalThis?.location?.pathname
  });
}

function registerGTMpageView() {
  GA4.gtag('event', 'page_view', {
    page_title: document?.title || '',
    page_location: window.location.pathname || '',
    previous_page_path: new URL(document.referrer || window.location.origin)?.pathname || ''
  });
}

export function useRegister(page: Ref<Page>) {
  const domain = globalThis?.location?.origin;
  Social.BASE_PATH = `${domain}/api/social`;

  const { isPreview, socialLoginStatus, nextPage, countryCode } = storeToRefs(useSiteStore());
  const { pageData: commonPageData, registerUser } = useRegisterCommon();
  const pageData: Ref = ref({
    ...commonPageData.value,
    isSubmitLoading: false,
    isSubmitDisabled: false,
    socialLoginStatus: socialLoginStatus.value,
    docLinks: useDocLinks()
  });

  function startLoading() {
    pageData.value.isSubmitLoading = true;
    pageData.value.isSubmitDisabled = true;
  }

  function endLoading() {
    pageData.value.isSubmitLoading = false;
    pageData.value.isSubmitDisabled = false;
  }

  async function handleRegister(form: RegisterPayload) {
    try {
      startLoading();
      await registerUser(form);
    } catch (ex) {
      Promise.reject(ex);
    } finally {
      endLoading();
    }
  }

  async function handleSocialLogin(provider: SocialLogins) {
    if (isPreview.value) return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
    const { result } = await Social.login({ provider });
    if (globalThis?.location?.href && result?.auth) globalThis.location.href = result?.auth;
  }

  async function handleSocialCallback(code: string, provider: 'google' | 'facebook' | 'apple') {
    if (!provider || !code) return;
    const { result } = await Social.callback({ provider, code });
    const { email = '', fullName = '' } = result || {};
    if (email) {
      handleRegister({
        subscriberId: email,
        ...(fullName && { fullName })
      });
    }
  }

  emitter.on('registrationFormSubmitted', function (form) {
    handleRegister(form as RegisterPayload);
  });

  emitter.on('registrationSocialLogin', function (provider) {
    handleSocialLogin(provider as SocialLogins);
  });

  emitter.on('registrationInputError', function (err: any) {
    if (isPreview.value) return;
    const [hasError, message] = err || [];
    if (hasError) {
      registerGTMError(message);
    } else {
      registerGTMSuccess();
    }
  });

  function checkSocialLoginCallbackUrlParams() {
    const route = useRoute();
    const code = route.query?.code;
    const provider = route.query?.provider;
    if (code && provider) {
      handleSocialCallback(code, provider);
    }
  }

  onMounted(() => {
    checkCurrentSession();
    registerGTMpageView();
    checkSocialLoginCallbackUrlParams();
  });

  return { page, pageData };
}
