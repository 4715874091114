<template>
  <NuxtLayout :name="layoutName">
    <template
      v-for="(section, index) of page?.sections"
      :key="section.id + `_${index}`"
    >
      <Section
        v-if="!canVisibleSurveyHeader(section)"
        :section="section"
        :locale="language"
        :page-data="pageData"
        :page-options="pageOptions"
        :style="{
          ...section.style,
          ...(
            ((hasFooter && section.type === 'footer') || (!hasFooter && section.type === 'zotloFooter'))
              ? { marginTop: 'auto'}
              : {}
          )
        }"
      >
        <template #default="elements">
          <ElementWrapper
            v-for="el of elements"
            :key="el.id"
            :page-data="pageData"
            :el="el"
            :locale="language"
            :page-options="pageOptions"
          />
        </template>
      </Section>
    </template>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useHead } from 'unhead';
import { storeToRefs } from 'pinia';
import { useSiteStore } from '@/stores/site';
import { PageType } from '@/types';
import { usePage } from '@/composables/usePage';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { getFirstPage } from '@/utils/session';
import { setNamedCookie, COOKIE } from '@/utils/cookie';
import getParamsFromQueryString from '@/utils/getParamsFromQueryString';
import '@shared/utils/emitter';
import type { Section as SectionType } from '@shared/types/model';
import { QuestionTypeEnum } from '@shared/types/quiz';
import { useSurveyStore } from '@/stores/survey';

(globalThis as any).__CDN_URL = useRuntimeConfig().NUXT_APP_CDN_URL || '';

const route = useRoute();
const { language, isStoreFlow } = storeToRefs(useSiteStore());
const { currentQuestion } = storeToRefs(useSurveyStore());
const { pageType, slug } = getBaseUrl(route?.fullPath);
const { page, pageData, pageOptions, head } = usePage(pageType as PageType || getFirstPage());
const hasFooter = computed(() => {
  return page.value?.sections?.find((section) => section.type === 'footer');
})

const layoutName = computed(() => isStoreFlow?.value ? "store-flow" : "default");

if (process.client) {
  const queryObject = getParamsFromQueryString(window.location.href);
  // Save UTM info as cookie
  const utmInfo = Object.entries(queryObject).reduce((acc, [key, value]) => {
    if (key && /^utm_/g.test(key?.trim())) acc[key?.trim()] = value;
    return acc;
  }, {} as Record<string, any>);

  if (Object.keys(utmInfo).length > 0) {
    setNamedCookie({
      cookieName: COOKIE.UTM,
      value: JSON.stringify(utmInfo),
      path: slug
    })
  }
}

function canVisibleSurveyHeader(section: SectionType) {
  return (
    (
      [
        QuestionTypeEnum.EndPage
      ].includes(currentQuestion.value?.type as QuestionTypeEnum) ||
      !currentQuestion.value
    ) &&
    section.type === 'surveyheader'
  )
}

useHead(head);
</script>

<style lang="postcss">
a {
  @apply underline;

  &:-webkit-any-link {
    color: -webkit-link;
  }
}
</style>
