import { useSiteStore } from '@/stores/site';
import { PageType } from '@/types';
import { getClientSidePageUrl } from '@/utils/getBaseUrl';

interface UseGeneratedLinksOptions {
  selector?: string;
}

export function useGeneratedLinks(options?: UseGeneratedLinksOptions) {
  const { selector = 'a.tw-element-button' } = options || {};

  const { availablePages, nextPage } = useSiteStore();
  const allPageTypes = Object.values(PageType);

  function generateLinks() {
    const elements = document.querySelectorAll(selector);
    elements.forEach((elementItem) => {
      if (hasLink(elementItem)) {
        const url = elementItem.getAttribute('href') || '';
        const page = ((url && url.split('/').filter(Boolean)[0]) || '') as PageType;
        if (allPageTypes.includes(page)) {
          if (availablePages.includes(page)) {
            // If there is a page link and the page exists, go to that page
            elementItem.setAttribute('href', getClientSidePageUrl(page as PageType));
            return;
          }
          // If there is a page link and the page does not exist, clear the link
          elementItem.setAttribute('href', `javascript:;`);
          return;
        }
        // If url is not page link, open it in a new tab
        elementItem.setAttribute('target', '_blank');
        return;
      }
      // Go to next page if there is no link
      elementItem.setAttribute('href', getClientSidePageUrl(nextPage as PageType));
    });
  }

  function hasLink(element: Element) {
    const hrefValue = element.getAttribute('href');
    return !!hrefValue && hrefValue !== '#';
  }

  onMounted(() => {
    setTimeout(() => {
      nextTick(() => generateLinks());
    }, 0);
  });

  return {};
}
