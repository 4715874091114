import type { Ref } from 'vue';
import { storeToRefs } from 'pinia';
import Quiz from '@/services/quiz';
import type { Page, PageBGOptions, PageOptions, Section, Element as ElementType } from '@/shared/types/model';
import emitter from '@/shared/utils/emitter';
import { useSurveyStore } from '@/stores/survey';
import { QuestionTypeEnum } from '@/shared/types/quiz';
import { getElementByType, getQuestionElement } from '@/shared/utils';
import { View } from '@/services/view';
import { useSiteStore } from '@/stores/site';
import { PageType } from '@/types';
import { checkCurrentSession } from '@/utils/session';
import { $wait } from '@/shared/utils/wait';
import { GTM, GA4 } from '@/utils/integrations';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { getUserAgent } from '@/utils';
import { useDocLinks } from './useDocLinks';
import { useSessionAnswers } from '@/shared/composable/useSessionAnswers';
import type { QuizResponse } from '@/types/quiz';

type AnswerType = { id: number; name: string; image: string; type: number }[] | string;

let prevSlug = '';
let previousAnswers: any = [];

function getQuestionTypes() {
  return Object.entries(QuestionTypeEnum).reduce((acc: Record<string, any>, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
}

function surveyGTMClick(question: any, clickText: string) {
  const qTypes = getQuestionTypes();

  GTM.push({
    event: 'customClick',
    pageSlug: question.questionId, // Editor'deki slug değeri,
    pageType: qTypes[question.type], // page type'lar gelecek,
    pageIndex: question.progress.now,
    clickTextName: clickText || ''
  });

  GA4.gtag('event', 'customClick', {
    pageSlug: question.questionId, // Editor'deki slug değeri,
    pageType: qTypes[question.type], // page type'lar gelecek,
    pageIndex: question.progress.now,
    clickTextName: clickText || ''
  });
}

function surveyGTMAnswer(question: any, answer: any) {
  const qTypes = getQuestionTypes();

  GTM.push({
    event: 'answer',
    pageSlug: question.questionId, // Editor'deki slug değeri,
    pageType: qTypes[question.type], // page type'lar gelecek,
    answer
  });

  GA4.gtag('event', 'answer', {
    pageSlug: question.questionId, // Editor'deki slug değeri,
    pageType: qTypes[question.type], // page type'lar gelecek,
    answer
  });
}

function surveyGTMVirtualPage(question: any, pageIndex: number) {
  const { fullPath, pageType } = getBaseUrl();
  const pagePath = '/'+ [fullPath, pageType, question.questionId].filter(Boolean).join('/');

  GTM.push({
    event: 'GAVirtual',
    virtualPageTitle: question.questionName,
    virtualPagePath: pagePath,
    previousPath: prevSlug,
    pageIndex
  });

  GA4.gtag('event', 'page_view', {
    page_title: question.questionName,
    page_location: pagePath,
    previous_page_path: new URL(document.referrer || window.location.origin)?.pathname || '',
  });

  prevSlug = pagePath;
}

export function useSurvey(page: Ref<Page>) {
  const surveyStore = useSurveyStore();
  const siteStore = useSiteStore();
  const { activeQuestion, currentQuestion, customPagination } = storeToRefs(surveyStore);
  const { language, isPreview, isStoreFlow, siteData } = storeToRefs(siteStore);
  const { sessionInfo, saveAnswerToTemp, clearSavedAnswers } = useSessionAnswers({
    sessionId: siteData.value?.sessionId || '',
    pageSlug: siteData.value?.pageSlug || '',
    flowId: siteData.value?.page.flowId || ''
  });

  const pageData = ref({
    answers: currentQuestion.value?.answers,
    hasLogic: false,
    fileElementError: '',
    answerRequired: !!currentQuestion.value?.required,
    isLoading: false,
    countryCode: siteData.value?.countryCode,
    customPagination,
    docLinks: useDocLinks(),
    sessionInfo
  });

  const domain = globalThis?.location?.origin || '';
  Quiz.BASE_PATH = `${domain}/api/quiz`;

  function updateBGSettings(question: Record<string, any>) {
    const pageOptions = JSON.parse(siteData.value?.pageOptions || '{}') as PageOptions;
    const pageSettings = pageOptions?.pages?.[PageType.QUIZ] || {};
    const settings = JSON.parse(question.settings || '{}') as PageBGOptions;

    let forceDesktopBG = !!pageSettings?.forceDesktopBG;
    let screenBGColor = pageSettings?.backgroundColor || '';
    let { desktop, tablet, mobile } = pageSettings?.backgroundImage || {};

    if (Object.prototype.hasOwnProperty.call(settings, 'forceDesktopBG')) {
      forceDesktopBG = !!settings?.forceDesktopBG;
    }
    if (settings.backgroundColor) {
      screenBGColor = settings.backgroundColor;
    }
    if (settings.backgroundImage?.desktop) {
      desktop = settings.backgroundImage.desktop
    }
    if (settings.backgroundImage?.tablet) {
      tablet = settings.backgroundImage.tablet;
    }
    if (settings.backgroundImage?.mobile) {
      mobile = settings.backgroundImage.mobile;
    }

    const bg = {
      color: screenBGColor,
      image: {
        desktop: desktop || '',
        tablet: (forceDesktopBG ? desktop : tablet) || '',
        mobile: (forceDesktopBG ? desktop : mobile) || '',
      }
    };

    const root = document.querySelector(':root') as HTMLElement;
    root?.style?.setProperty('--app-backgroundColor', bg.color);
    root?.style?.setProperty('--app-backgroundImage-desktop', bg.image.desktop);
    root?.style?.setProperty('--app-backgroundImage-tablet', bg.image.tablet);
    root?.style?.setProperty('--app-backgroundImage-mobile', bg.image.mobile);
  }

  function goToZotloStorePaymentPage() {
    const zotloStoreUrl = useRuntimeConfig()?.public?.NUXT_ZOTLO_STORE_URL;

    function getZotloStoreLanguageRoute() {
      const zotloStoreDefaultLocale = useRuntimeConfig()?.public?.NUXT_ZOTLO_STORE_DEFAULT_LOCALE;
      const zotloStoreOtherLocales = useRuntimeConfig()?.public?.NUXT_ZOTLO_STORE_LOCALES?.filter(lang => lang !== zotloStoreDefaultLocale);
      const quizLanguage = language.value || 'en';
      if (quizLanguage === zotloStoreDefaultLocale) return '';
      if (zotloStoreOtherLocales?.includes(quizLanguage)) return `${quizLanguage}/`;
      return '';
    }

    const { storeHash, sessionId } = siteData?.value || {};
    const storePaymentUrl = `${zotloStoreUrl}${getZotloStoreLanguageRoute()}${storeHash}/init?uuid=${sessionId}`;
    if (globalThis?.location?.href) globalThis.location.href = storePaymentUrl;
    return;
  }

  function updateQuestionPage(question: QuizResponse) {
    if (checkCurrentSession()) return;

    surveyStore.addQuestion(question);
    surveyStore.setActiveQuestion(question.id);
    updateBGSettings(question);

    const questionContext = JSON.parse(question.context || '[]') as Section[];
    const baseSectionTypes = ['header', 'surveyheader', 'footer', 'zotloFooter'] as Section['type'][]
    page.value.sections = page.value.sections.filter((section) => baseSectionTypes.includes(section.type));

    pageData.value.answerRequired = !!question.required;
    pageData.value.answers = question.answers || [];

    if (Object.prototype.hasOwnProperty.call(question, 'hasLogic')) {
      pageData.value.hasLogic = !!question.hasLogic;
    }

    const baseSectionsFooter = page.value.sections.find((section) => section.type === 'footer');
    const baseSectionsZotloFooter = page.value.sections.find((section) => section.type === 'zotloFooter');

    if (baseSectionsFooter) {
      const index = page.value.sections.indexOf(baseSectionsFooter);
      page.value.sections.splice(index, 0, ...questionContext)
    } else if (baseSectionsZotloFooter) {
      const index = page.value.sections.indexOf(baseSectionsZotloFooter);
      page.value.sections.splice(index, 0, ...questionContext)
    } else {
      page.value.sections.push(...questionContext)
    }

    const isEndingPage = question.type === QuestionTypeEnum.EndPage;

    for (const section of page.value.sections || []) {
      for (const column of section.columns) {
        if (question.progress) {
          const progressEl = getElementByType(column.elements, 'surveyprogress');
          if (progressEl) {
            progressEl.attr.total = question.progress.total;
            progressEl.attr.current = question.progress.now;
            progressEl.attr.hasLogic = pageData.value.hasLogic;
          }
        }

        const questionEl = getQuestionElement(column.elements);
        if (questionEl) {
          questionEl.attr.options = JSON.parse(JSON.stringify(question?.options || []));
          break;
        }
      }
    }

    if (question.questionId && globalThis?.location) {
      globalThis.location.hash = question.questionId;
    }

    if (process.client) {
      // When new questions loaded scroll to top
      globalThis?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    sendQuestionView({
      quizId: question.pollId,
      questionId: question.id,
      quizLanguage: language.value || ''
    });

    surveyGTMVirtualPage(question, customPagination.value.history.length);

    if (isEndingPage) {
      const interval = setInterval(() => { window.history.forward() }, 50);

      let waitTime = 3000;
      for (const section of page.value.sections) {
        for (const column of section.columns) {
          const endingElement = getElementByType(column.elements, 'endingprogress') as ElementType<'endingprogress'>;
          if (endingElement) {
            waitTime = parseFloat('' + endingElement.options?.waitTime || '3') * 1000;
          }
        }
      }

      setTimeout(() => {
        clearSavedAnswers()
        setVisitedPages(PageType.QUIZ);
        surveyStore.resetPagination();
        if (isStoreFlow.value) {
          if (isPreview.value) return clearInterval(interval);
          return goToZotloStorePaymentPage();
        }
        goToPage(siteStore.getNextPage());
        clearInterval(interval);
      }, waitTime);
    }
  }

  function sendQuestionView(payload: { quizId: number; questionId: number; quizLanguage: string }) {
    try {
      if (process.server || isPreview.value) return;
      const domain = globalThis?.location.origin;
      View.BASE_PATH = `${domain}/api/event`;

      View.question({
        ...payload,
        ...getUserAgent(PageType.QUIZ)
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function sendQuestionAnswerEvent(
    question: QuizResponse,
    answer: {
      options: number[];
      input: string;
    }
  ) {
    try {
      if (process.server || isPreview.value) return;
      const domain = globalThis?.location.origin;
      View.BASE_PATH = `${domain}/api/event`;

      const answerId = answer.options.length > 0 ? answer.options.join(',') : answer.input || '';

      await View.questionAnswer({
        quizId: question.pollId,
        questionId: question.id,
        quizLanguage: language.value || '',
        answerId,
        ...getUserAgent(PageType.QUIZ)
      }).finally(() => checkCurrentSession());
    } catch (e) {
      console.log(e);
    }
  }

  async function prevQuestion() {
    try {
      $wait.start('prevQuestion');
      setLoading(true);
      const response = await Quiz.prevQuestion(activeQuestion.value as number)
        .then((response) => {
          previousAnswers = response.result?.answers
          surveyStore.prevPagination();
          updateQuestionPage(response.result);
        })
        .finally(() => checkCurrentSession());

      return response;
    } finally {
      setLoading(false);
      $wait.end('prevQuestion');
    }
  }

  function hasSameAnswers(previous: any, current: any) {
    const previousOptions = previous[0]?.options;
    const currentAnswer = current.answer;

    if (previousOptions?.length) {
      const previousAnswers = previousOptions.map((option: any) => option.optionId).sort();
      const currentAnswers = currentAnswer.options.sort();
      return JSON.stringify(previousAnswers) === JSON.stringify(currentAnswers);
    }

    const previousOptionText = previous[0]?.optionText;
    if (previousOptionText) {
      return previousOptionText === currentAnswer.input;
    }

    return false;
  }

  function nextQuestion() {
    return Quiz.nextQuestion(activeQuestion.value as number)
      .then((response) => {
        surveyStore.nextPagination(response.result.type);
        updateQuestionPage(response.result);
      })
      .finally(() => checkCurrentSession());
  }

  function setLoading(status: boolean) {
    pageData.value.isLoading = status;
  }

  async function sendAnswer(options: AnswerType) {
    try {
      setLoading(true);
      if (checkCurrentSession()) return;
      $wait.start('sendAnswer');
      const payload = {
        questionId: activeQuestion.value as number,
        answer: {
          options: Array.isArray(options) ? options.map((option) => option.id) : [],
          input: Array.isArray(options) ? '' :
            typeof options === 'number' && !isNaN(options) ? options : (options || '')
        }
      };

      if (hasSameAnswers(previousAnswers, payload)) {
        nextQuestion()
        return
      }

      if (!isPreview.value) {
        const answersWithLabel = payload.answer?.options?.map((id) => {
          return (currentQuestion.value?.options || []).find((option: Record<string, any>) => option.id === id)?.name || id;
        });
        surveyGTMAnswer(currentQuestion.value, payload.answer?.input || answersWithLabel);
      }

      const question = currentQuestion.value;

      const response = await Quiz.saveAnswers(payload)
        .then((response) => {
          if (response.meta.errorCode) return response;
          if (question) {
            sendQuestionAnswerEvent(question, payload.answer);

            const saveableTypes = [QuestionTypeEnum.HeightInput, QuestionTypeEnum.WeightInput];
            if (saveableTypes.includes(question.type)) saveAnswerToTemp(payload);
          }
          surveyStore.nextPagination(response.result.type);
          updateQuestionPage(response.result);
          return response;
        })
        .finally(() => checkCurrentSession());

      return response;
    } finally {
      setLoading(false);
      $wait.end('sendAnswer');
    }
  }

  async function fileUpload(payload: any) {
    try {
      $wait.start('fileUpload');
      Quiz.BASE_PATH = 'quiz';
      const response = await Quiz.fileUpload(payload).then((res) => {
        const msg = res?.meta?.message;
        const hasError = res?.meta?.errorCode;

        if (hasError) {
          pageData.value.fileElementError = msg || hasError;
          return res;
        }

        return res;
      });
      return response;
    } finally {
      $wait.end('fileUpload');
      Quiz.BASE_PATH = `${domain}/api/quiz`;
    }
  }

  emitter.on('fileElementErrorStatus', function (status: any) {
    pageData.value.fileElementError = status;
  });

  emitter.on('nextQuestion', async function (data: any) {
    try {
      $wait.start('nextQuestion');
      setLoading(true);
      if ($wait.is(['fileUpload', 'sendAnswer'])) return;
      if (checkCurrentSession()) return;
      if (!activeQuestion.value) return;
      let answer = data?.[1];
      const targetEl = data?.[0]?.target.innerText;

      if (!isPreview.value) surveyGTMClick(currentQuestion.value, targetEl);

      const isInfoPage = currentQuestion.value?.type === QuestionTypeEnum.MidScreen;
      if (isInfoPage) {
        if (!isPreview.value) surveyGTMAnswer(currentQuestion.value, '');
        sendQuestionAnswerEvent(currentQuestion.value, { options: [], input: '' });
        await nextQuestion();
      } else {
        if (currentQuestion.value?.type === QuestionTypeEnum.FileUpload) {
          const file = answer;
          const existingFileUrls = [] as string[];
          let isUploadNecessary = false;
          if (file) {
            const formData = new FormData();
            file.forEach((fileItem: any) => {
              if (typeof fileItem === 'string') {
                existingFileUrls.push(fileItem);
              } else if (fileItem instanceof File) {
                isUploadNecessary = true;
                formData.append(`file[]`, fileItem as File);
              }
            });
            formData.append('questionId', '' + activeQuestion.value);
            let newFileUrls = [] as string[];
            if (isUploadNecessary) {
              const response = await fileUpload(formData);
              if (response?.meta?.errorCode) return false;
              newFileUrls = response?.result?.map(item => item.fileUrl);
            }
            answer = JSON.stringify([...newFileUrls, ...existingFileUrls])
          } else {
            answer = '';
          }
        }
        await sendAnswer(answer as AnswerType);
      }
    } finally {
      setLoading(false);
      $wait.end('nextQuestion');
    }
  });

  emitter.on('goBack', function () {
    if (!activeQuestion.value) return;
    if (checkCurrentSession()) return;
    prevQuestion();
  });

  emitter.on('skipQuestion', function (data: any) {
    try {
      $wait.start('skipQuestion');

      if ($wait.is(['fileUpload', 'sendAnswer'])) return;
      if (checkCurrentSession()) return;
      if (!activeQuestion.value) return;

      const targetEl = data[0]?.target.innerText;

      if (!isPreview.value) {
        surveyGTMClick(currentQuestion.value, targetEl);
        surveyGTMAnswer(currentQuestion.value, '');
      }

      if (currentQuestion.value) {
        sendQuestionAnswerEvent(currentQuestion.value, { options: [], input: '' });
      }
      nextQuestion();
    } finally {
      $wait.end('skipQuestion');
    }
  });

  emitter.on('searchLocation', async function (data: any) {
    const [, payload] = data;
    const response = await Quiz.getLocation(payload);
    emitter.emit('getLocation', response.result.data);
  });

  if (process.client) {
    onBeforeMount(() => {
      Quiz.init()
        .then((response) => {
          if (response.meta.httpStatus !== 200) return;
          surveyStore.nextPagination(response.result.type);
          updateQuestionPage(response.result);
        })
        .finally(() => checkCurrentSession());
    })
  }

  return { page, pageData };
}
