import { useSiteStore } from '@/stores/site';

export function useApplyAppLinksToElements() {
  const { applicationLinks } = useSiteStore();

  const LINKS_BY_ATTR = {
    "data-privacy-policy-link": applicationLinks?.privacyUrl,
    "data-terms-of-use-link": applicationLinks?.termsOfServiceUrl,
    "data-customer-service-link": applicationLinks?.customerSupportUrl,
    "data-generic-app-link": applicationLinks?.genericDownloadUrl
  }

  function setLinkToElByAttr(attr = '', link = '') {
    const element = document?.querySelector(`[${attr}]`) as HTMLLinkElement;
    if (link) {
      element?.setAttribute('href', link);
      element?.setAttribute('target', '_blank');
    }
  }

  function applyLinks() {
    Object.entries(LINKS_BY_ATTR).forEach(([attr, link]) => {
      setLinkToElByAttr(attr, link);
    })
  }

  onMounted(() => {
    setTimeout(() => {
      nextTick(() => applyLinks());
    }, 100);
  });

  return {};
}
