import { storeToRefs } from 'pinia';
import { useSiteStore } from '@/stores/site';

export const ProviderSdks = {
  init() {
    const { providers, isPreview } = storeToRefs(useSiteStore());
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!providers.value) return headScripts;

    if (providers.value?.applePay && !isPreview.value) {
      const script = {
        async: true,
        crossorigin: true,
        body: true,
        src: 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js'
      };
      headScripts.script.push(script);
    }

    return headScripts;
  }
};
