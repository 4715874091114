import { api } from './api';

export type CreditCardDetails = {
  email?: string;
  cardHolder: string;
  cardNumber: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardCVV: string;
}

type UtmDetails = {
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
}

export type PaymentPayload = {
  email?: string;
  providerKey: string;
  packageId: number;
  creditCardDetails?: CreditCardDetails;
  utm?: UtmDetails;
  // For custom payments
  transactionId?: string; // stripe, applePay, googlePay
  secureHash?: string; // stripe
  acceptPolicy?: boolean;
  applePayToken?: string;
};

export enum PaymentResultStatus {
  REDIRECT = 'REDIRECT',
  COMPLETE = 'COMPLETE'
}

export enum PaymentCallbackStatus {
  SUCCESS = 'success',
  FAIL = 'fail'
}

export class Payment {
  static BASE_PATH = 'payment';

  static async sendPayment(payload: PaymentPayload, headers?: Record<string, any>) {
    const response = await api.post(`${Payment.BASE_PATH}`, payload, headers);
    return response.data;
  }

  static async checkSubscriptionStatus(headers?: Record<string, any>) {
    const response = await api.get(`${Payment.BASE_PATH}/subscription-status`, headers);
    return response.data;
  }

  static async getSubscriptionDetail(headers?: Record<string, any>) {
    const response = await api.get(`${Payment.BASE_PATH}/subscription-detail`, headers);
    return response.data;
  }

  static async getPaymentToken(payload: {
    packageId: number;
    providerKey: 'stripe';
  }, headers?: Record<string, any>) {
    const response = await api.post(`${Payment.BASE_PATH}/token`, payload, headers);
    return response.data;
  }

  static async initProvider(payload: {
    providerKey: 'applePay' | 'googlePay';
  }, headers?: Record<string, any>) {
    const response = await api.post(`${Payment.BASE_PATH}/init`, payload, headers);
    return response.data;
  }

  static async startSession(payload: {
    providerKey: 'applePay'; // applePay only
    sessionUrl: string;
    transactionId: string;
  }, headers?: Record<string, any>) {
    const response = await api.post(`${Payment.BASE_PATH}/session`, payload, headers);
    return response.data;
  }
}

export default Payment;
